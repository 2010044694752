import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { Variables } from 'graphql-request';
import { gql, request } from 'graphql-request';

import { getGraphQlEndpoint } from '@/api/backendAPI';

import {
  getCartIdCookie,
  invalidateCartData,
  saveCartIdCookie,
} from '../utils/cartUtils';
import type {
  SavePromotion,
  SavePromotion_cart_savePromotion,
  SavePromotionVariables,
} from './types/SavePromotion';

export const query = gql`
  query SavePromotion($cartId: String, $promotionCode: String!) {
    cart(cartId: $cartId) {
      savePromotion(promotionCode: $promotionCode) {
        cartId
        resultCategory
        errorCode
        invalidReason
      }
    }
  }
`;

export function withQueryKey(vars: SavePromotionVariables) {
  return {
    queryKey: ['savePromotion', vars.cartId, vars.promotionCode],
    queryFn: async (): Promise<
      SavePromotion_cart_savePromotion | null | undefined
    > => {
      const url = getGraphQlEndpoint()!;
      if (!vars.promotionCode) return null;

      try {
        const data: SavePromotion | undefined = await request(
          url,
          query,
          vars as unknown as Variables,
        );
        const saveResult = data?.cart?.savePromotion;

        // Save new cart ID in case it changed.
        if (
          saveResult?.cartId &&
          (!saveResult.invalidReason || !saveResult.errorCode)
        ) {
          saveCartIdCookie(saveResult.cartId);
        }

        // Cart was updated, so clear cache for any fetches that use the cart.
        invalidateCartData();

        return saveResult;
      } catch (err: any) {
        if (err?.response?.status === 404) {
          return {
            cartId: null,
            resultCategory: 'user_exception',
            errorCode: 'not_found',
            invalidReason: 'not_found',
          };
        }

        return {
          cartId: null,
          resultCategory: 'unexpected_error',
          errorCode: 'unexpected_error',
          invalidReason: 'unexpected_error',
        };
      }
    },
  };
}

export function useSavePromotion(
  promotionCode: string,
): UseQueryResult<SavePromotion_cart_savePromotion | null | undefined, Error> {
  const cartId = getCartIdCookie();
  return useQuery({
    ...withQueryKey({ cartId, promotionCode }),
    enabled: !!promotionCode && !!cartId,
  });
}
